import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from 'redux-thunk'
import {authReducer} from "./AuthReducer";
import {notificationReducer} from "./NotificationReducer";
import {searchReducer} from "./SearchReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    notification: notificationReducer,
    searchCache: searchReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));


export default store;
