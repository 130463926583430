import ToolBar from "./ToolBar";
import PageLoading from "../components/PageLoading";
import useExitPrompt from "../util/useExitPrompt";

const Page = ({title, loading, isDirty = false, ...props}) => {

    const {children} = props;

    useExitPrompt(isDirty);

    return (
        <>

            <ToolBar title={title} className="top-nav" fixed={true}/>

            {!loading &&
            <div className="page">
                {children}
            </div>
            }

            {loading && <PageLoading/>}
        </>
    );
}

export default Page;
