import Grid from '@mui/material/Grid';

const ListPageActions = ({left, right, children, ...props}) => {

    return (
        <div className="list-page-actions">
            <Grid container spacing={3}>
                <Grid item md={6} style={{textAlign: 'left'}}>
                    {left}
                </Grid>
                <Grid item md={6}>
                    {right}
                    {children}
                </Grid>
            </Grid>
        </div>
    )

}

export default ListPageActions;
