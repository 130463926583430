import {TextField} from "@mui/material";
import React from "react";

const Input = React.forwardRef(({
                                    error,
                                    variant = "outlined",
                                    margin = "dense",
                                    helperText,
                                    showErrorMessage = true,
                                    ...props
                                }, ref) => {

    return (
        <>
            <TextField
                error={error && true}
                inputRef={ref}
                margin={margin}
                variant={variant}
                fullWidth
                autoComplete="off"
                helperText={showErrorMessage ? error?.message : undefined}
                {...props}
            />
        </>
    )

});

export default Input;
