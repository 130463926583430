import Config from "../Config";

export function cleanUrl(str) {
    return str?.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '')
        .toLowerCase();
}


export function getImageUrl({src, width, height}) {
    let query = '';
    if (width) {
        query += `?w=${width}`;
    }
    if (height) {
        if (query.length > 0) {
            query += `&h=${height}`;
        } else {
            query += `?h=${height}`;
        }
    }

    return `${Config.CDN_URL}/${src}${query}`;
}


export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}


export function formatPrice(number, currency) {
    return new Intl.NumberFormat('tr-TR', {style: 'currency', currency: currency}).format(number);
}


export function checkRole(user, roles) {
    return user?.roles?.map((item) => item.name).some(role => roles.includes(role));
}