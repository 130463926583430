import axios from 'axios';
import {ActionType} from "../store/ActionType";

const setupInterceptors = (store) => {

    axios.interceptors.request.use(
        config => {
            const state = store?.getState();
            const token = state?.auth?.token;
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch({
                    type: ActionType.LOGOUT
                });
            }
            return Promise.reject(error.response.data);
        } else if (error.message === 'Network Error') {
            return Promise.reject("Sistem sunucularına ulaşılamadı.Lütfen daha sorna tekrar deneyin.");
        }
        return Promise.reject(error);
    });
}

const NetworkService = {
    setupInterceptors
};

export default NetworkService;
