import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const usePagination = ({id, service, defaultSearchData = {}, defaultOrderBy, defaultMax = 50}) => {


    const dispatch = useDispatch();
    const {results} = useSelector(state => state.searchCache);
    const [inited, setInited] = useState(false);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [count, setCount] = useState(0);
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [errors, setErrors] = useState(null);

    const [max, setMax] = useState(defaultMax);

    const [orderBy, setOrderBy] = useState(defaultOrderBy)

    useEffect(() => {
        setPage(0);
    }, [service, searchData, orderBy]);

    const search = useCallback(async () => {
        setLoading(true);
        try {
            const key = id ? id : service.constructor.name;
            const cached = results[key];

            if (cached && !inited) {
                setData(cached.data);
                setCount(cached.resultCount);
                setPageCount(cached.pageCount);
                setPage(cached.page);
            } else {
                const {data, resultCount, pageCount} = await service.search({
                    page: page,
                    data: searchData,
                    max,
                    orderBy
                });
                setData(data);
                setCount(resultCount);
                setErrors(null);
                //const _pages = Math.ceil(resultCount / max);
                setPageCount(pageCount);

                /*
                dispatch({
                    type: ActionType.SET_SEARCH_RESULT,
                    key: key,
                    data: {data, resultCount, pageCount, page}
                });*/
            }
        } catch (e) {
            setErrors(e)
            setData([]);
            setCount(0);
            setPageCount(0);
        }
        setLoading(false);
        setInited(true);
    }, [service, max, searchData, orderBy, page]);

    useEffect(() => {
        (async () => {
            await search();
        })();
    }, [page, search]);

    const reset = () => {
        setPage(0)
        setSearchData(defaultSearchData);
        setOrderBy(defaultOrderBy);
    }

    return {
        loading,
        data,
        setData,
        errors,
        page,
        pageCount,
        count,
        setPage,
        searchData,
        setSearchData,
        orderBy,
        max,
        setMax,
        setOrderBy,
        reset,
        search,
        service,
        setLoading
    }
}

export default usePagination;
