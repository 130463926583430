import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";

const AddButton = ({onClick, ...props}) => {
    const {t} = useTranslation();
    return (
        <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon/>}
            onClick={onClick}>
            {t('button.addNew')}
        </Button>
    );
}

export default AddButton;
