const API_URL = 'https://api.totaltipsbet.com/v1/api';
const CDN_URL = 'https://totaltipsbet.com/uploads';
//const API_URL = 'http://localhost:4880/api';
//const CDN_URL = 'http://localhost:4880/uploads';


const VERSION = '1.0';

const MAX_RESULT = 10;
const COOKIE_DOMAIN = "api.totaltipsbet.com";
//const COOKIE_DOMAIN = undefined;


const COOKIE_CONFIG = {
    maxAge: (60 * 60 * 24 * 120),
    path: "/",
    domain: COOKIE_DOMAIN,
    secure: false,
    sameSite: "Lax"
}

const COLOR_CATEGORY_ID = 1;

const Config = {
    API_URL,
    CDN_URL,
    MAX_RESULT,
    VERSION,
    COOKIE_CONFIG,
    COLOR_CATEGORY_ID,
};

export default Config;
