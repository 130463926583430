const findEnumById = (type, id) => {
    return Object.values(type).find(item => item.id === id);
}


const MessageType = {
    INFO: "info",
    ERROR: "error"
}


const YesNoType = {
    YES: {id: 1, label: "label.yes"},
    NO: {id: 0, label: "label.no"},
}

const PrivilegeTypes = [
    {id: 1, label: 'privilegeType.menu'},
];

const CategoryType = {
    PARENT: {id: 1, label: 'categoryType.parent'},
    SUB: {id: 2, label: 'categoryType.sub'},
}

const CategoryMenuType = [
    "SHOW_IN_CATEGORY", "SHOW_IN_BAND_ITSELF", "SHOW_ALL_IN"
]

const CurrencyTypes = ["TRY", "USD", "EUR"];

const CurrencySign = {
    "TRY": "₺",
    "USD": "$",
    "EUR": "€"
}

const PageTypes = [
    "HELP", "BLOG", "STATIC"
]

const FooterPositions = [
    {id: 1, label: 'footerPosition.left'},
    {id: 2, label: 'footerPosition.right'},
]

export {
    MessageType,
    findEnumById,
    YesNoType,
    PrivilegeTypes,
    CategoryType,
    CategoryMenuType,
    CurrencyTypes,
    PageTypes,
    FooterPositions
}

