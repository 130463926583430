import BaseService from "./BaseService";

class RoleService extends BaseService {

    constructor() {
        super("/roles");
    }

}

const roleService = new RoleService();

export default roleService;
