import React from 'react';
import {Collapse, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuItem from "./MenuItem";
import {useTranslation} from "react-i18next";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const MenuGroup = ({group, isOpen = false, onOpenChange}) => {


    const {t} = useTranslation();

    const toggleMenu = () => {
        onOpenChange && onOpenChange(group.title)
    }

    return (
        <>
            <ListItem button onClick={toggleMenu}>
                <ListItemIcon>
                    {group.icon}
                </ListItemIcon>
                <ListItemText primary={t(group.title)}/>
                {isOpen ? <ExpandLess style={{color: 'white'}}/> : <ExpandMore style={{color: 'white'}}/>}
            </ListItem>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense={true}>
                    {group.items.filter(item => !item.excludeMenu).map((page, key) => (
                        <MenuItem
                            to={page.path}
                            value={t(page.title)}
                            className={'submenuItem'}
                            icon={<MoreHorizIcon/>}
                            subtitle={t(page.subtitle)} key={key}
                        />
                    ))}
                </List>
            </Collapse>

        </>
    );

};

export default MenuGroup;
