import axios from "axios";
import Config from "../Config";


export const easyGet = async (path, data = {}) => {
    const response = await axios.get(Config.API_URL + path,
        {
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    return response.data;
}

export const easyDownload = async (path) => {
    const response = await axios.get(Config.API_URL + path,
        {
            timeout: 60000,
            responseType: 'blob'
        }
    );
    return response.data;
}

export const easyPost = async (path, data = {}) => {
    const response = await axios.post(Config.API_URL + path,
        data,
        {
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    return response.data;
}

export const easyPut = async (path, data = {}) => {
    const response = await axios.put(Config.API_URL + path,
        data,
        {
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );

    return response.data;
}

export const easySearch = async (path, page = 0, data, max = Config.MAX_RESULT, orderBy = null) => {
    try {


        const _data = {};
        if (data) {
            Object.keys(data).forEach(key => {
                if (data[key]) {
                    _data[key] = data[key];
                }
            })
        }

        const obj = {
            page: page,
            max: max,
            ...(data && {..._data}),
            ...(orderBy && {orderBy})
        };

        const params = new URLSearchParams(obj).toString();

        const response = await axios.get(`${Config.API_URL}${path}?${params}`,
            {
                timeout: 60000,
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );

        return response.data;

    } catch (e) {
        console.error(e);
        return null;
    }
}

export const easyDelete = async (path, data = {}) => {
    const response = await axios.delete(Config.API_URL + path,
        {data},
        {
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );

    return response.data;
}
