import format from 'date-fns/format'

export const formatDateTime = (datetime) => {
    if (datetime) {
        return format(datetime, 'dd.MM.yyyy HH:mm');
    }
    return "";
}

export const formatDate = (date) => {
    if (date) {
        return format(date, 'dd.MM.yyyy');
    }
    return "";
}

export const parseAndFormatDateForInput = (date) => {
    if (date) {
        return format(new Date(date), 'yyyy-MM-dd');
    }
    return "";
}

export const parseAndFormatDate = (date) => {
    if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
    }
    return "";
}

export const parseAndFormatDateTime = (datetime) => {
    return format(new Date(datetime), 'dd.MM.yyyy HH:mm');
}
