import Config from "../Config";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import FileUploadDialog from "../components/FileUploadDialog";

const ImagePickerButton = ({image, setImage}) => {

    const {t} = useTranslation();
    const [isOpenFileUploadDialog, setOpenFileUploadDialog] = useState(false);

    return (
        <>
            <div style={{textAlign: "center", marginTop: 24}}>
                {image && <img src={`${Config.CDN_URL}/${image}`} alt="" width={100}/>}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%"
                }}>
                    <Button variant="contained" color="inherit"
                            onClick={() => setOpenFileUploadDialog(true)}>
                        {t('button.imagePicker')}
                    </Button>

                    {image &&
                        <Button variant="contained" color="error" sx={{marginLeft: 1}}
                                onClick={() => setImage(null)}>
                            {t('button.deleteImage')}
                        </Button>
                    }
                </div>
            </div>
            <FileUploadDialog
                open={isOpenFileUploadDialog}
                accept="image/png"
                onClose={() => setOpenFileUploadDialog(false)}
                onDoneCallback={(result) => {
                    setImage(result);
                    setOpenFileUploadDialog(false);
                }}
            />
        </>
    )
}

export default ImagePickerButton;
