import LockIcon from '@mui/icons-material/Lock';
import UserListPage from "./pages/auths/UserListPage";
import UserEditPage from "./pages/auths/UserEditPage";
import SettingsIcon from '@mui/icons-material/Settings';
import ApplicationListPage from "./pages/commons/applications/ApplicationListPage";
import ApplicationEditPage from "./pages/commons/applications/ApplicationEditPage";
import TeamListPage from "./pages/commons/teams/TeamListPage";
import TeamEditPage from "./pages/commons/teams/TeamEditPage";
import LeagueListPage from "./pages/commons/leagues/LeagueListPage";
import LeagueEditPage from "./pages/commons/leagues/LeagueEditPage";
import CategoryListPage from "./pages/commons/categories/CategoryListPage";
import CategoryEditPage from "./pages/commons/categories/CategoryEditPage";
import SubscriptionListPage from "./pages/commons/subscriptions/SubscriptionListPage";
import SubscriptionEditPage from "./pages/commons/subscriptions/SubscriptionEditPage";
import SellIcon from '@mui/icons-material/Sell';
import CouponListPage from "./pages/coupon/CouponListPage";
import CouponEditPage from "./pages/coupon/CouponEditPage";

const Routers = [

    {
        title: 'menu.coupons',
        path: '/coupons',
        icon: <SellIcon/>,
        privilege: ['USER', 'ADMIN'],
        isGroup: true,
        items: [
            {
                title: 'menu.couponCreate',
                path: '/coupons/create',
                component: CouponEditPage,
                privilege: 'coupons',
            },
            {
                title: 'menu.couponList',
                path: '/coupons/list',
                component: CouponListPage,
                privilege: 'coupons',
            },
            {
                title: 'menu.coupons',
                path: '/coupons/edit/:id',
                component: CouponEditPage,
                privilege: 'coupons',
                excludeMenu: true,
            },
        ]
    },


    {
        title: 'menu.definitions',
        path: '/settings',
        icon: <SettingsIcon/>,
        privilege: ['ADMIN'],
        isGroup: true,
        items: [

            // applications
            {
                title: 'menu.applications',
                path: '/commons/applications',
                component: ApplicationListPage,
                privilege: 'commons.applications.list'
            },
            {
                title: 'menu.applications',
                path: '/commons/applications/create',
                component: ApplicationEditPage,
                privilege: 'commons.applications.create',
                excludeMenu: true
            },
            {
                title: 'menu.applications',
                path: '/commons/applications/edit/:id',
                component: ApplicationEditPage,
                privilege: 'commons.applications.edit',
                excludeMenu: true
            },

            // teams
            {
                title: 'menu.teams',
                path: '/commons/teams',
                component: TeamListPage,
                privilege: 'commons.teams.list'
            },
            {
                title: 'menu.teams',
                path: '/commons/teams/create',
                component: TeamEditPage,
                privilege: 'commons.teams.create',
                excludeMenu: true
            },
            {
                title: 'menu.teams',
                path: '/commons/teams/edit/:id',
                component: TeamEditPage,
                privilege: 'commons.teams.edit',
                excludeMenu: true
            },

            // leagues
            {
                title: 'menu.leagues',
                path: '/commons/leagues',
                component: LeagueListPage,
                privilege: 'commons.leagues.list'
            },
            {
                title: 'menu.leagues',
                path: '/commons/leagues/create',
                component: LeagueEditPage,
                privilege: 'commons.leagues.create',
                excludeMenu: true
            },
            {
                title: 'menu.leagues',
                path: '/commons/leagues/edit/:id',
                component: LeagueEditPage,
                privilege: 'commons.leagues.edit',
                excludeMenu: true
            },

            // subscriptions
            {
                title: 'menu.subscriptions',
                path: '/commons/subscriptions',
                component: SubscriptionListPage,
                privilege: 'commons.subscriptions.list'
            },
            {
                title: 'menu.subscriptions',
                path: '/commons/subscriptions/create',
                component: SubscriptionEditPage,
                privilege: 'commons.subscriptions.create',
                excludeMenu: true
            },
            {
                title: 'menu.subscriptions',
                path: '/commons/subscriptions/edit/:id',
                component: SubscriptionEditPage,
                privilege: 'commons.subscriptions.edit',
                excludeMenu: true
            },

            // categories
            {
                title: 'menu.categories',
                path: '/commons/categories',
                component: CategoryListPage,
                privilege: 'commons.categories.list'
            },
            {
                title: 'menu.categories',
                path: '/commons/categories/create',
                component: CategoryEditPage,
                privilege: 'commons.categories.create',
                excludeMenu: true
            },
            {
                title: 'menu.categories',
                path: '/commons/categories/edit/:id',
                component: CategoryEditPage,
                privilege: 'commons.categories.edit',
                excludeMenu: true
            },

        ]
    },

    //auth
    {
        title: 'menu.auths',
        path: '/auths',
        icon: <LockIcon/>,
        privilege: ['ADMIN'],
        isGroup: true,
        items: [


            {
                title: 'menu.users',
                path: '/users',
                component: UserListPage,
                privilege: 'auths.users.list'
            },
            {
                title: 'title.createUsers',
                path: '/users/create',
                component: UserEditPage,
                privilege: 'auths.users.create',
                excludeMenu: true
            },
            {
                title: 'title.editUsers',
                path: '/users/edit/:id',
                component: UserEditPage,
                privilege: 'auths.users.edit',
                excludeMenu: true
            },
        ]
    },


];

export default Routers;
