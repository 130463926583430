import BaseService from "./BaseService";

class TeamService extends BaseService {

    constructor() {
        super("/teams");
    }

}

const teamService = new TeamService();

export default teamService;
