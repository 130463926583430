import React, {useEffect, useRef, useState} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import subscriptionService from "../service/SubscriptionService";
import {useTranslation} from "react-i18next";
import {Controller} from "react-hook-form";
import RenderTags from "./RenderTags";


const SubscriptionInput = ({
                               name,
                               label,
                               applicationId,
                               defaultValue,
                               rules,
                               error,
                               multiple = false,
                               disabled,
                               control,
                               limitTags = 2
                           }) => {

    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const {t} = useTranslation();

    const searchTimeout = useRef(null);

    useEffect(() => {
        let active = true;
        setLoading(true);
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }
        searchTimeout.current = setTimeout(async () => {
            try {
                setLoading(true);
                const {data} = await subscriptionService.search({
                    start: 0,
                    data: {name: inputValue, application: applicationId},
                    max: 50
                });
                const result = data.map(item => ({...item}));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {
                active && setLoading(false);
            }
        }, 500);
        return () => {
            active = false;
        };
    }, [inputValue]);

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };


    return (
        <Controller
            render={({field}) => (
                <Autocomplete
                    {...field}
                    multiple={multiple}
                    getOptionLabel={(subscription) => subscription.name || ""}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    options={options}
                    filterOptions={(x) => x}
                    loading={loading}
                    limitTags={limitTags}
                    disabled={disabled}
                    renderTags={RenderTags}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label || (multiple ? t('label.subscriptions') : t('label.subscription'))}
                            error={error && true}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            helperText={error?.message}
                            onChange={handleChange}
                        />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : (multiple ? [] : "")}
            rules={rules}
        />
    );
};

export default SubscriptionInput;
