import BaseService from "./BaseService";
import {easyPut} from "../util/Fetch";

class CouponService extends BaseService {

    constructor() {
        super("/coupons");
    }


    async publish(id) {
        return await easyPut(`${this.path}/${id}/publish`);
    }

    async unPublish(id) {
        return await easyPut(`${this.path}/${id}/unPublish`);
    }

}

const couponService = new CouponService();

export default couponService;
