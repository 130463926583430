import PageLoading from "../components/PageLoading";
import {useEffect} from "react";
import {useCookies} from "react-cookie";
import Config from "../Config";
import {useDispatch} from "react-redux";
import {ActionType} from "../store/ActionType";
import {useNavigate} from "react-router-dom";

const LogoutPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);

    useEffect(() => {

        removeCookie('access_token', Config.COOKIE_CONFIG);
        removeCookie('refresh_token', Config.COOKIE_CONFIG);

        setTimeout(() => {
            dispatch({
                type: ActionType.LOGOUT
            });
            navigate("/")
        }, 100);

    }, []);

    return (
        <PageLoading/>
    )
}

export default LogoutPage;
