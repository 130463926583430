import SaveIcon from "@mui/icons-material/Save";
import SubmitButton from "./SubmitButton";
import React from "react";
import {useTranslation} from "react-i18next";

const SaveButton = ({loading}) => {
    const {t} = useTranslation();
    return (
        <SubmitButton loading={loading} startIcon={<SaveIcon/>}>
            {t('button.save')}
        </SubmitButton>
    )

}
export default SaveButton;
