import React from 'react';
import NavLinkMui from "./NavLinkMui";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";


const MenuItem = ({to, value, icon, className, exact = true, subtitle}) => {
    return (

        <ListItem button to={to} component={NavLinkMui}>

            <ListItemIcon>
                {icon}
            </ListItemIcon>

            <ListItemText style={{whiteSpace: 'nowrap'}}
                          primary={`${value} ${subtitle ? ' - ' + subtitle : ''}`}
                          className="no-wrap"/>
        </ListItem>
    )
};

export default MenuItem;
