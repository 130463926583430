import {useFieldArray, useFormContext} from "react-hook-form";
import {DefaultContainerComponent} from "../../components/DataTable";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import Input from "../../ui/Input";
import React, {useEffect} from "react";
import LeagueInput from "../../ui/LeagueInput";
import TeamInput from "../../ui/TeamInput";
import {withStyles} from "@mui/styles";
import {styled} from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Select from "../../ui/Select";
import MatchStatus from "../../enums/MatchStatus";
import MenuItem from "@mui/material/MenuItem";

const StyledHeaderCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#f6f7f9',
        color: '#26415c',
        fontWeight: 'bold',
        padding: "3px 8px"
    }
}))(TableCell);

const StyledInputCell = withStyles((theme) => ({
    body: {
        padding: 0,
        "& .MuiFormControl-root": {
            margin: "0px",

            "& .MuiOutlinedInput-root": {
                borderRadius: "0px",
            }
        }
    }
}))(TableCell);

const StyledInputRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#f8fbff',
    },
}));


const CouponEditMatchTable = ({coupon}) => {

    const {t} = useTranslation();
    const {register, control, formState: {errors}} = useFormContext();

    const matches = useFieldArray({
        control,
        name: "matches",
    });

    useEffect(() => {
        if (Array.isArray(coupon?.matches)) {
            console.log(coupon.matches)
            matches.remove();
            coupon.matches.forEach(item => {
                matches.append({...item});
            });
        }
    }, [coupon]);

    const onAddNew = () => {
        matches.append({
            index: matches.fields.length + 1,
            time: "",
            league: null,
            teamHome: null,
            teamAway: null,
            forecast: "",
            odds: "",
            status: "NOT_START",
            homeScore: "0",
            awayScore: "0",
        })
    }

    const checkKeyDown = (e) => {
        if (e.key === "Enter") {
            onAddNew();
        }
    };

    return (
        <DefaultContainerComponent
            title={t('title.matches')}
            action={
                <Button onClick={onAddNew} startIcon={<AddIcon/>}>{t('button.add')}</Button>
            }>
            <TableContainer>
                <Table className="data-table">
                    <TableHead>
                        <TableRow>
                            <StyledHeaderCell style={{width: "50px"}}>{t('label.index')}</StyledHeaderCell>
                            <StyledHeaderCell>{t('label.league')}</StyledHeaderCell>
                            <StyledHeaderCell>{t('label.teamHome')}</StyledHeaderCell>
                            <StyledHeaderCell>{t('label.teamAway')}</StyledHeaderCell>
                            <StyledHeaderCell style={{width: "100px"}}>{t('label.time')}</StyledHeaderCell>
                            <StyledHeaderCell>{t('label.forecast')}</StyledHeaderCell>
                            <StyledHeaderCell style={{width: "75px"}}>{t('label.odds')}</StyledHeaderCell>
                            <StyledHeaderCell style={{width: "60px"}}>{t('label.homeScore')}</StyledHeaderCell>
                            <StyledHeaderCell style={{width: "60px"}}>{t('label.awayScore')}</StyledHeaderCell>
                            <StyledHeaderCell style={{width: "100px"}}>{t('label.status')}</StyledHeaderCell>
                            <StyledHeaderCell style={{width: "50px"}}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {matches.fields.map((field, index) => (
                            <StyledInputRow key={field.id}>
                                <StyledInputCell>
                                    <Input
                                        defaultValue={field.index}
                                        placeholder={t('label.index')}
                                        showErrorMessage={false}
                                        error={errors?.matches && errors.matches[index]?.index}
                                        {...register(`matches.${index}.index`, {
                                            required: t('validation.required')
                                        })}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <LeagueInput
                                        name={`matches.${index}.league`}
                                        placeholder={t('label.league')}
                                        defaultValue={field?.league}
                                        control={control}
                                        showErrorMessage={false}
                                        rules={{required: t('validation.required')}}
                                        error={errors?.matches && errors.matches[index]?.league}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <TeamInput
                                        name={`matches.${index}.teamHome`}
                                        placeholder={t('label.teamHome')}
                                        defaultValue={field?.teamHome}
                                        control={control}
                                        showErrorMessage={false}
                                        rules={{required: t('validation.required')}}
                                        error={errors?.matches && errors.matches[index]?.teamHome}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <TeamInput
                                        name={`matches.${index}.teamAway`}
                                        placeholder={t('label.teamAway')}
                                        defaultValue={field?.teamAway}
                                        control={control}
                                        showErrorMessage={false}
                                        rules={{required: t('validation.required')}}
                                        error={errors?.matches && errors.matches[index]?.teamAway}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <Input
                                        defaultValue={field.time}
                                        placeholder={t('label.time')}
                                        showErrorMessage={false}
                                        error={errors?.matches && errors.matches[index]?.time}
                                        {...register(`matches.${index}.time`, {
                                            required: t('validation.required')
                                        })}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <Input
                                        defaultValue={field.forecast}
                                        placeholder={t('label.forecast')}
                                        showErrorMessage={false}
                                        error={errors?.matches && errors.matches[index]?.forecast}
                                        {...register(`matches.${index}.forecast`, {
                                            required: t('validation.required')
                                        })}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <Input
                                        defaultValue={field.odds}
                                        placeholder={t('label.odds')}
                                        showErrorMessage={false}
                                        onKeyDown={checkKeyDown}
                                        error={errors?.matches && errors.matches[index]?.odds}
                                        {...register(`matches.${index}.odds`, {
                                            required: t('validation.required')
                                        })}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <Input
                                        defaultValue={field.homeScore}
                                        placeholder={t('label.homeScore')}
                                        showErrorMessage={false}
                                        onKeyDown={checkKeyDown}
                                        error={errors?.matches && errors.matches[index]?.homeScore}
                                        {...register(`matches.${index}.homeScore`, {
                                            required: t('validation.required')
                                        })}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <Input
                                        defaultValue={field.awayScore}
                                        placeholder={t('label.awayScore')}
                                        showErrorMessage={false}
                                        onKeyDown={checkKeyDown}
                                        error={errors?.matches && errors.matches[index]?.awayScore}
                                        {...register(`matches.${index}.awayScore`, {
                                            required: t('validation.required')
                                        })}
                                    />
                                </StyledInputCell>
                                <StyledInputCell>
                                    <Select
                                        name={`matches.${index}.status`}
                                        rules={{required: t('validation.required')}}
                                        error={errors?.matches && errors.matches[index]?.status}
                                        defaultValue={field.status}
                                        showErrorMessage={false}
                                        control={control}>
                                        {MatchStatus.map(item => {
                                            return (
                                                <MenuItem key={item} value={item}>
                                                    {t(`matchStatus.${item}`)}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </StyledInputCell>
                                <StyledInputCell>
                                    <Button color={"error"}
                                            onClick={() => matches.remove(index)}>
                                        {t('button.delete')}
                                    </Button>
                                </StyledInputCell>
                            </StyledInputRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
        </DefaultContainerComponent>
    )
}

export default CouponEditMatchTable;
