import {ActionType} from "./ActionType";

const InitData = {
    results: {}
};


export const searchReducer = (state = InitData, action) => {

    switch (action.type) {
        case ActionType.SET_SEARCH_RESULT: {
            const results = {...state.results};
            results[action.key] = action.data;
            return {
                ...state,
                results: results
            }
        }

        default: {
            return state;
        }
    }
}
