const PageActions = (props) => {

    return (
        <div className="page-actions">
            {props.children}
        </div>
    )

}

export default PageActions;
