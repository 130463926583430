import {MessageType} from "./Enums";
import {toast} from 'react-toastify';

const useMessage = () => {

    return (message, type = MessageType.INFO, vertical = 'top', horizontal = 'right') => {
        toast[`${type}`](message, {
            closeOnClick: true,
            autoClose: 5000,
            //theme:'dark'
        })

    };
};

export default useMessage;
