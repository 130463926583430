import React from 'react';
import {Route, Routes} from "react-router-dom";
import SideMenu from "../menu/SideMenu";
import Dashboard from "./Dashboard";
import LogoutPage from "./LogoutPage";
import Routers from "../routers";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {checkRole} from "../util/Util";

const HomePage = (props) => {

    const {t} = useTranslation();
    const {user} = useSelector(state => state.auth);

    const onMenuToggle = (pinned) => {

        const content = document.getElementById('content');
        // const toolbar = document.getElementById('toolbar');
        if (pinned) {
            content.classList.add("expanded");
            //   toolbar.classList.add("expanded");
        } else {
            content.classList.remove("expanded");
            // toolbar.classList.remove("expanded");
        }
    };

    return (
        <div className="App">
            <SideMenu onToggle={onMenuToggle}/>
            <div id="content" className="content">
                <Routes>
                    <Route path="/" element={<Dashboard/>}/>
                    <Route path="/logout" element={<LogoutPage/>}/>
                    {Routers.map((item, key) => {
                        const hasRole = checkRole(user, item.privilege);
                        if (hasRole) {
                            return (
                                item.isGroup === true ?
                                    item.items.map((page, key) => {
                                            return (
                                                <Route path={page.path} key={key} element={
                                                    <page.component title={t(page.title)}/>}
                                                />
                                            )
                                        }
                                    ) :
                                    <Route path={item.path} key={key} element={
                                        <item.component title={t(item.title)}/>
                                    }/>
                            );
                        } else {
                            return <React.Fragment/>
                        }
                    })}
                </Routes>
            </div>
        </div>
    );

};

export default HomePage;
