import Page from "../../../layout/Page";

import {useTranslation} from "react-i18next";
import usePagination from "../../../util/usePagination";
import leagueService from "../../../service/LeagueService";
import {DataTable} from "../../../components/DataTable";
import Container from '@mui/material/Container';
import ListPageActions from "../../../components/ListPageActions";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import AddButton from "../../../ui/AddButton";
import DeleteDialog from "../../../components/DeleteDialog";

const LeagueListPage = ({title}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [selected, setSelected] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const columns = [
        {
            name: "name",
            label: t('label.name'),
            searchable: true
        }
    ];

    const leagues = usePagination({
        service: leagueService
    })

    const ActionButtons = () => (
        <ListPageActions>
            <AddButton onClick={() => {
                navigate(`/commons/leagues/create`)
            }}/>
        </ListPageActions>
    )

    return (
        <Page title={title}>
            <Container maxWidth={"md"}>
                <ActionButtons/>
                <DataTable
                    title={t('title.leagueList')}
                    columns={columns} {...leagues}
                    onEdit={(item) => {
                        navigate(`/commons/leagues/edit/${item._id}`);
                    }}
                    onDelete={(item) => {
                        setSelected(item);
                        setOpenDeleteDialog(true);
                    }}
                />
                <ActionButtons/>
            </Container>

            <DeleteDialog
                item={selected}
                itemService={leagueService}
                message={selected?.name}
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelected(null)
                }}
                onDoneCallback={leagues.search}
            />
        </Page>
    );
}

export default LeagueListPage;
