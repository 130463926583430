import {ActionType} from "./ActionType";

const InitialData = {
    user: null,
    isLoggedIn: false,
    panelLang: 'tr',
    token: null,
};


export const authReducer = (state = InitialData, action) => {
    switch (action.type) {
        case ActionType.CHANGE_LANG: {
            return {
                ...state,
                panelLang: action.lang
            };
        }

        case ActionType.LOGIN: {
            return {
                ...state,
                user: action.user,
                token: action.token,
                isLoggedIn: true
            }
        }

        case ActionType.LOGOUT: {
            return {
                ...state,
                user: null,
                token: null,
                isLoggedIn: false
            }
        }

        default: {
            return state;
        }
    }
}
