import React, {useEffect, useState} from "react";
import Page from "../../layout/Page";
import {useNavigate, useParams} from "react-router-dom";
import couponService from "../../service/CouponService";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import PageActions from "../../components/PageActions";
import {FormProvider, useForm} from "react-hook-form";
import {MessageType} from "../../util/Enums";
import useMessage from "../../util/useMessage";
import BackButton from "../../ui/BackButton";
import SaveButton from "../../ui/SaveButton";
import CouponEditInputPanel from "./CouponEditInputPanel";
import CouponEditMatchTable from "./CouponEditMatchTable";
import Grid from '@mui/material/Grid';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Button from "@mui/material/Button";

const CouponEditPage = ({title}) => {

    const param = useParams();
    const couponId = param?.id;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [coupon, setCoupon] = useState(null);
    const [isPublished, setPublished] = useState(false);

    const methods = useForm();


    useEffect(() => {
        let mount = true;
        if (couponId) {
            (async () => {
                setPageLoading(true);
                try {
                    const [_result] = await Promise.all([
                        couponService.get(couponId),
                    ]);
                    mount && initPageValues(_result);
                } catch (e) {
                    console.error(e);
                } finally {
                    mount && setPageLoading(false);
                }
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
            return () => mount = false;
        }
    }, [couponId]);

    const initPageValues = (_coupon) => {
        setCoupon(_coupon);
        setPublished(_coupon.status === 'PUBLISHED');
        methods.setValue("application", _coupon.application);
    }

    const onSubmit = async (data) => {

        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                id: couponId | null,
                ...data,
            }
            const resultObject = await couponService.saveOrUpdate(couponId, obj);
            showMessage(t(couponId ? 'message.updated' : 'message.created'));

            if (!couponId) {
                navigate(`/coupons/edit/${resultObject._id}`, {replace: true});
            } else {
                initPageValues(resultObject);
            }

        } catch ({code, message}) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    }

    const onPublish = async () => {
        try {
            setLoading(true);
            if (isPublished) {
                await couponService.unPublish(couponId);
                setPublished(false);
                showMessage(t('message.unpublished'));
            } else {
                await couponService.publish(couponId);
                setPublished(true);
                showMessage(t('message.published'));
            }
            setLoading(false);
        } catch ({code, message}) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false)
        }
    }

    const ActionPanel = () => (
        <PageActions>
            <BackButton loading={loading}/>
            <SaveButton loading={loading}/>

            {couponId &&
                <Button
                    variant="contained"
                    color={isPublished ? "secondary" : "success"}
                    disabled={loading}
                    startIcon={loading ? null : (
                        isPublished ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowUpIcon/>
                    )}
                    onClick={onPublish}>
                    {t(isPublished ? 'button.unPublish' : 'button.publish')}
                </Button>
            }

        </PageActions>
    );

    const checkKeyDown = (e) => {
        if (e.key === "Enter") e.preventDefault();
    };


    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth={"xl"}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
                        <ActionPanel/>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <CouponEditInputPanel coupon={coupon}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CouponEditMatchTable coupon={coupon}/>
                            </Grid>
                        </Grid>
                        <ActionPanel/>
                    </form>
                </FormProvider>
            </Container>
        </Page>
    );

}

export default CouponEditPage;
