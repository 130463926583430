import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import {createTheme, StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import {enUS, trTR} from "@mui/material/locale";
import ReduxNotificationHandler from "./util/ReduxNotificationHandler";
import {useDispatch, useSelector} from "react-redux";
import PrivateRoute from "./PrivateRoute";
import HomePage from "./pages/HomePage";
import PageLoading from "./components/PageLoading";
import LoginPage from "./pages/LoginPage";
import authService from "./service/AuthService";
import {ActionType} from "./store/ActionType";
import Config from "./Config";
import {useCookies} from "react-cookie";
import LogoutPage from "./pages/LogoutPage";


const themeOptions = {
    components: {
        MuiTextField: {
            defaultProps: {
                size: "small"
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            }
        }
    }
}

const locales = {
    "tr": trTR,
    "en": enUS
}

const MainRouter = () => {

    const {panelLang} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);

    const [loading, setLoading] = useState(true);

    const [theme, setTheme] = useState(
        createTheme({
            ...themeOptions
        }, trTR)
    );

    useEffect(() => {
        if (panelLang) {
            setTheme(createTheme({
                ...themeOptions
            }, locales[panelLang]));

        }
    }, [panelLang]);


    useEffect(() => {
        (async () => {
            try {
                const accessToken = cookies.access_token;
                if (accessToken) {
                    const account = await authService.getAccount(accessToken);
                    if (account) {
                        dispatch({
                            type: ActionType.LOGIN,
                            user: account,
                            token: accessToken
                        });
                    } else {
                        removeCookie('access_token', Config.COOKIE_CONFIG);
                        /*
                        const refreshToken = cookies.refresh_token;
                        if (refreshToken) {
                            const {access_token, refresh_token} = await authService.refresh(refreshToken);
                            const account = await authService.getAccount(access_token);
                            if (account) {

                                setCookie('access_token', access_token, Config.COOKIE_CONFIG);
                                setCookie('refresh_token', refresh_token, Config.COOKIE_CONFIG);

                                dispatch({
                                    type: ActionType.LOGIN,
                                    user: account,
                                    token: access_token
                                });
                            }
                        }*/
                    }
                }
            } catch (e) {
                try {
                    removeCookie('access_token', Config.COOKIE_CONFIG);
                    removeCookie('refresh_token', Config.COOKIE_CONFIG);
                } catch (e) {
                    console.error(e);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [cookies, dispatch, removeCookie, setCookie]);

    const Loading = () => {
        return (
            <PageLoading/>
        );
    };

    const SwitchView = () => {
        return (
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>

                <Route path="/*" element={
                    <PrivateRoute>
                        <HomePage/>
                    </PrivateRoute>
                }/>


                <Route path="/logout" element={<LogoutPage/>}/>
            </Routes>
        );
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {loading ? <Loading/> : <SwitchView/>}
                <ReduxNotificationHandler/>
            </ThemeProvider>
        </StyledEngineProvider>
    );

}

export default MainRouter;
