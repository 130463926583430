import Page from "../../../layout/Page";

import {useTranslation} from "react-i18next";
import usePagination from "../../../util/usePagination";
import subscriptionService from "../../../service/SubscriptionService";
import {DataTable} from "../../../components/DataTable";
import Container from '@mui/material/Container';
import ListPageActions from "../../../components/ListPageActions";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import AddButton from "../../../ui/AddButton";
import DeleteDialog from "../../../components/DeleteDialog";

const SubscriptionListPage = ({title}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [selected, setSelected] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const TrueFalseOptions = (
        <>
            <option value="true">{t('label.true')}</option>
            <option value="false">{t('label.false')}</option>
        </>
    )

    const columns = [
        {
            name: "name",
            label: t('label.name'),
            searchable: true
        },
        {
            name: "application",
            label: t('label.application'),
            execute: (application) => application?.name
        },
        {
            name: "bonus",
            label: t('label.bonus'),
            execute: (bonus) => t(`label.${bonus}`),
            searchable: true,
            searchType: "select",
            selectOptions: TrueFalseOptions
        }
    ];

    const subscriptions = usePagination({
        service: subscriptionService
    })

    const ActionButtons = () => (
        <ListPageActions>
            <AddButton onClick={() => {
                navigate(`/commons/subscriptions/create`)
            }}/>
        </ListPageActions>
    )

    return (
        <Page title={title}>
            <Container maxWidth={"md"}>
                <ActionButtons/>
                <DataTable
                    title={t('title.subscriptionList')}
                    columns={columns} {...subscriptions}
                    onEdit={(item) => {
                        navigate(`/commons/subscriptions/edit/${item._id}`);
                    }}
                    onDelete={(item) => {
                        setSelected(item);
                        setOpenDeleteDialog(true);
                    }}
                />
                <ActionButtons/>
            </Container>

            <DeleteDialog
                item={selected}
                itemService={subscriptionService}
                message={selected?.name}
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelected(null)
                }}
                onDoneCallback={subscriptions.search}
            />
        </Page>
    );
}

export default SubscriptionListPage;
