import {useEffect, useRef, useState} from "react";
import categoryService from "../service/CategoryService";

const CategoryOptions = ({applicationId}) => {

    const timeout = useRef();
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let mount = true;
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(async () => {
            try {
                const filter = {
                    ...(applicationId && {application: applicationId})
                }
                const {data} = await categoryService.search({page: 0, data: filter, max: 100});
                if (mount) {
                    setOptions(data);
                    setLoading(false);
                }
            } catch (e) {
                console.error(e);
            } finally {
                mount && setLoading(false);
            }
        }, 500);
        return () => {
            mount = false;
        };
    }, [applicationId]);

    if (loading) {
        return <option>Yukleniyor</option>
    } else {
        return options.map((item => (
            <option key={item._id} value={item._id}>{item.name}</option>
        )));
    }
}

export default CategoryOptions;