export const ActionType = {

    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",

    ADD_NOTIFICATION: "ADD_NOTIFICATION",
    REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",

    CHANGE_LANG: "CHANGE_LANG",

    SET_SEARCH_RESULT: "SET_SEARCH_RESULT",


};
