import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationTR from './i18n/tr.json';
import translationEN from './i18n/en.json';

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        resources: {
            tr: {
                translation: translationTR
            },
            en: {
                translation: translationEN
            }
        },
        lng: "tr",
        fallbackLng: "tr",
        initImmediate: false,
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;
