import React, {useState} from 'react';
import MenuItem from "./MenuItem";

import {useTranslation} from 'react-i18next';
import boldIcon from '../res/bold-logo.png';
import {AppBar, List, Switch, Toolbar} from "@mui/material";
import {makeStyles} from "@mui/styles";

import HomeIcon from '@mui/icons-material/Home';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import MenuGroup from "./MenuGroup";
import Routers from "../routers";
import {useSelector} from "react-redux";
import {checkRole} from "../util/Util";

const useStyles = makeStyles(theme => ({

    boldLabel: {
        flexGrow: 1,
        fontWeight: 'bold',
        whiteSpace: "nowrap"
    },
    boldIcon: {
        marginRight: 20
    },
    toolBar: {
        paddingLeft: 20,
        paddingRight: 20
    },
    appBar: {
        backgroundColor: '#343a40',
        color: 'white'
    }
}));

const RouterItem = ({item, activeGroup, onActiveGroupChange}) => {

    const {t} = useTranslation();

    return item.isGroup === true ?
        <MenuGroup group={item}
                   isOpen={item.title === activeGroup}
                   onOpenChange={onActiveGroupChange}/> :
        <MenuItem to={item.path} value={t(item.title)} icon={item.icon}/>

}

const SideMenu = ({onToggle}) => {

    const {t} = useTranslation();
    const [pin, setPin] = useState(false);
    const [activeGroup, setActiveGroup] = useState("");

    const {user} = useSelector(state => state.auth);

    const classes = useStyles();

    const onChange = (event) => {
        setPin(event.target.checked);
        onToggle && onToggle(event.target.checked);
    };

    const onActiveGroupChange = (key) => {
        if (key !== activeGroup) {
            setActiveGroup(key);
        } else {
            setActiveGroup("");
        }
    }

    const BoldIcon = () => {
        return (
            <img alt="bold" src={boldIcon} className="boldIcon"/>
        );
    };

    return (
        <nav id="side-menu" className={"side-menu " + (pin ? 'expanded' : '')}>

            <AppBar position="static" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolBar} variant="regular">
                    <div className={classes.boldIcon}>
                        <BoldIcon/>
                    </div>
                    <div className={classes.boldLabel}>
                        Yönetim Paneli
                    </div>
                    <Switch
                        checked={pin}
                        onChange={onChange}
                        size="small"
                    />
                </Toolbar>
            </AppBar>

            <List className="menu">
                <MenuItem to="/" value={t('menu.dashboard')} icon={<HomeIcon/>}/>
                {Routers.filter(item => !item.excludeMenu).map((item, key) => {
                    const hasRole = checkRole(user, item.privilege);
                    if (hasRole) {
                        return (
                            <RouterItem
                                key={key}
                                item={item}
                                activeGroup={activeGroup}
                                onActiveGroupChange={onActiveGroupChange}
                            />
                        )
                    } else {
                        return <React.Fragment/>
                    }
                })}
            </List>

            <ul className="logout">
                <MenuItem to="/logout" value={t('menu.logout')} icon={<PowerSettingsNewIcon/>}/>
            </ul>
        </nav>
    );
};


export default SideMenu;
