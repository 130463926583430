import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {MessageType} from "../../util/Enums";
import useMessage from "../../util/useMessage";
import userService from "../../service/UserService";
import SaveIcon from "@mui/icons-material/Save";
import Input from "../../ui/Input";

const UserPasswordEditDialog = ({user = null, open, onClose, onDoneCallback}) => {

    const {t} = useTranslation();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, watch, formState: {errors}, reset} = useForm();

    const password = watch("password");

    useEffect(() => {
        if (open) {
            reset();
            setLoading(false);
        }
    }, [open, reset]);

    const onSubmit = async (data) => {
        if (loading) return false;
        try {
            setLoading(true);
            await userService.changePassword(user?._id, data);
            showMessage(t('message.passwordUpdated'));
            onClose && onClose();
            onDoneCallback && onDoneCallback();
        } catch ({code, message}) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            scroll="paper">
            <DialogTitle>{t('title.changeUserPasswordDialog')}</DialogTitle>
            <DialogContent dividers={true}>
                <Input
                    type="password"
                    label={t('label.password')}
                    error={errors?.password}
                    {...register("password", {
                        required: t('validation.required')
                    })}
                />

                <Input
                    type="password"
                    label={t('label.passwordAgain')}
                    error={errors?.passwordAgain}
                    {...register("passwordAgain", {
                        validate: value => value === password || t('validation.passwordAgain')
                    })}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <Button loading={loading} onClick={handleSubmit(onSubmit)} startIcon={<SaveIcon/>}>
                    {t('button.save')}
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default React.memo(UserPasswordEditDialog);
