import Page from "../../layout/Page";
import Chip from '@mui/material/Chip';
import {useTranslation} from "react-i18next";
import usePagination from "../../util/usePagination";
import couponService from "../../service/CouponService";
import {DataTable} from "../../components/DataTable";
import Container from '@mui/material/Container';
import ListPageActions from "../../components/ListPageActions";
import {useNavigate} from "react-router-dom";
import {useMemo, useState} from "react";
import AddButton from "../../ui/AddButton";
import DeleteDialog from "../../components/DeleteDialog";
import {parseAndFormatDate, parseAndFormatDateForInput} from "../../util/DateUtil";
import ApplicationOptions from "../../components/ApplicationOptions";
import CategoryOptions from "../../components/CategoryOptions";
import CouponStatus from "../../enums/CouponStatus";

const CouponListPage = ({title}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [selected, setSelected] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const toDate = parseAndFormatDateForInput(new Date());

    const coupons = usePagination({
        service: couponService,
        defaultSearchData: {
            date: undefined
        }
    });

    const columns = useMemo(() => [
        {
            name: "date",
            label: t('label.date'),
            execute: (date) => parseAndFormatDate(date),
            type: Date,
            searchable: true
        },
        {
            name: "application",
            label: t('label.application'),
            execute: (application) => application.name,
            searchable: true,
            searchType: "select",
            selectOptions: <ApplicationOptions/>
        },
        {
            name: "category",
            label: t('label.category'),
            execute: (category) => category?.name,
            searchable: true,
            searchType: "select",
            selectOptions: <CategoryOptions applicationId={coupons.searchData?.application}/>
        },
        {
            name: "title",
            label: t('label.title'),
            searchable: true,
        },
        {
            name: "status",
            label: t('label.status'),
            execute: (status) => (
                <Chip label={t(`couponStatus.${status}`)}
                      size="small"
                      color={status === 'DRAFT' ? 'default' : 'success'}
                />
            ),
            searchable: true,
            searchType: "select",
            selectOptions: CouponStatus.map(item => (
                <option key={item} value={item}>{t(`couponStatus.${item}`)}</option>
            ))
        }
    ], [toDate, coupons]);


    const ActionButtons = () => (
        <ListPageActions>
            <AddButton onClick={() => {
                navigate(`/coupons/create`)
            }}/>
        </ListPageActions>
    )

    return (
        <Page title={title}>
            <Container maxWidth={"lg"}>
                <ActionButtons/>
                <DataTable
                    title={t('title.couponList')}
                    columns={columns} {...coupons}
                    onEdit={(item) => {
                        navigate(`/coupons/edit/${item._id}`);
                    }}
                    onDelete={(item) => {
                        setSelected(item);
                        setOpenDeleteDialog(true);
                    }}
                />
                <ActionButtons/>
            </Container>

            <DeleteDialog
                item={selected}
                itemService={couponService}
                message={selected?.name}
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelected(null)
                }}
                onDoneCallback={coupons.search}
            />
        </Page>
    );
}

export default CouponListPage;
