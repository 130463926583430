import React from "react";
import {makeStyles} from "@mui/styles";
import {Card, CardContent, CardHeader} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    card: {
        height: '100%',
    },
    title: {
        fontSize: '1.2rem'
    },
    root: {
        paddingBottom: '0px',
    },
    contentRoot: {
        paddingTop: '8px',
    }
}));

const Panel = ({title, subtitle, action, ...props}) => {

    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardHeader
                title={title}
                subheader={subtitle}
                classes={{title: classes.title, root: classes.root}}
                action={action}
            />
            <CardContent classes={{root: classes.contentRoot}}>
                {props.children}
            </CardContent>
        </Card>
    )

}

export default Panel;
