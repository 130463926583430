import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import Loading from './Loading';
import fileUploadService from '../util/FileUploadService';
import {MessageType} from "../util/Enums";
import useMessage from "../util/useMessage";

const FileUploadDialog = ({open, onClose, accept, onDoneCallback}) => {
    const {t} = useTranslation();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [file, setFile] = useState();

    const onFileChange = event => {
        // Update the state
        setFile(event.target.files[0]);
    };

    const onUploadProgressCallback = event => {
        const _progress = Math.round((100 * event.loaded) / event.total);
        setProgress(_progress);
    };

    const onUpload = () => {
        setLoading(true);
        fileUploadService
            .upload(file, file.name, 'FILE', '', onUploadProgressCallback)
            .then(response => {
                setLoading(false);
                onDoneCallback && onDoneCallback(response.data.file);
            })
            .catch(({code, message}) => {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
                setLoading(false);
            });
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'md'} scroll="paper">
            <DialogTitle>{t('title.fileUploadDialog')}</DialogTitle>
            <DialogContent dividers>
                {!loading && (
                    <input type="file" onChange={onFileChange} accept={accept}/>
                )}

                {loading && (
                    <div style={{textAlign: 'center'}}>
                        <Loading/>
                        <div style={{padding: 12}}>%{progress}</div>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>

                {file && (
                    <Button onClick={onUpload} disabled={loading}>
                        {t('button.upload')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default FileUploadDialog;
