import axios from "axios";
import Config from "../Config";

class AuthService {

    constructor() {
        this.path = "/auths/token";
    }

    async login(username, password) {
        const form = new URLSearchParams();
        //form.append("grant_type", "password");
        form.append("username", username);
        form.append("password", password);

        const response = await fetch(Config.API_URL + this.path, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: form

        })

        return await response.json();
    }

    async refresh(refreshToken) {
        const form = new URLSearchParams();
        form.append("grant_type", "refresh_token");
        form.append("refresh_token", refreshToken);


        const response = await fetch(Config.API_URL + this.path, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: form

        });

        return await response.json();
    }

    async getAccount(accessToken) {

        const response = await axios.get(`${Config.API_URL}/auths/account`,
            {
                timeout: 60000,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            }
        );

        return response.data;

    }

}

const authService = new AuthService();

export default authService;
