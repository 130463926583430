import React from 'react';
import NetworkService from "./util/NetworkService";
import store from "./store/Store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import MainRouter from "./MainRouter";
import {ToastContainer} from 'react-toastify';
import "./styles/App.scss";
import "./styles/SideMenu.scss";
import 'react-day-picker/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';


NetworkService.setupInterceptors(store);

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <MainRouter/>
            </BrowserRouter>
            <ToastContainer/>
        </Provider>
    );
}

export default App;
