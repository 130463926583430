import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import React from "react";

const SubmitButton = React.memo(({startIcon, type = "submit", color = "error", loading, ...props}) => {

    const LoadingIcon = () => (
        <CircularProgress size={18} style={{color: "white"}}/>
    );

    return (
        <Button
            {...props}
            type={type}
            color={color}
            variant="contained"
            disabled={loading}
            startIcon={loading ? <LoadingIcon/> : startIcon}>
            {props.children}
        </Button>
    )

});

export default SubmitButton;
