import React, {useEffect, useState} from "react";
import Page from "../../../layout/Page";
import {useNavigate, useParams} from "react-router-dom";
import teamService from "../../../service/TeamService";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import PageActions from "../../../components/PageActions";
import {useForm} from "react-hook-form";
import {MessageType} from "../../../util/Enums";
import useMessage from "../../../util/useMessage";
import BackButton from "../../../ui/BackButton";
import SaveButton from "../../../ui/SaveButton";
import Panel from "../../../ui/Panel";
import Input from "../../../ui/Input";
import ImagePickerButton from "../../../ui/ImagePickerButton";

const TeamEditPage = ({title}) => {

    const param = useParams();
    const teamId = param?.id;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [team, setTeam] = useState(null);
    const [image, setImage] = useState(null);

    const {handleSubmit, register, formState: {errors}} = useForm();

    useEffect(() => {
        if (teamId) {
            (async () => {
                setPageLoading(true);
                try {
                    const [_result] = await Promise.all([
                        teamService.get(teamId),
                    ]);
                    initPageValues(_result);
                } catch (e) {
                    console.error(e);
                }
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
        }
    }, [teamId]);

    const initPageValues = (_team) => {
        setTeam(_team);
        setImage(_team?.image);
    }

    const onSubmit = async (data) => {

        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                id: teamId | null,
                ...data,
                image
            }
            const resultObject = await teamService.saveOrUpdate(teamId, obj);
            showMessage(t(teamId ? 'message.updated' : 'message.created'));

            if (!teamId) {
                navigate(`/commons/teams/edit/${resultObject._id}`, {replace: true});
            } else {
                initPageValues(resultObject);
            }

        } catch ({code, message}) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    }


    const ActionPanel = () => (
        <PageActions>
            <BackButton loading={loading}/>
            <SaveButton loading={loading}/>
        </PageActions>
    );


    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth={"sm"}>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel/>

                    <Panel title={t('title.team')}>
                        <Input
                            defaultValue={team?.name}
                            label={t('label.name')}
                            error={errors?.name}
                            {...register("name", {
                                required: t('validation.required')
                            })}
                        />

                        <ImagePickerButton {...{image, setImage}} />
                    </Panel>
                    <ActionPanel/>
                </form>

            </Container>
        </Page>
    );

}

export default TeamEditPage;
