import React, {useEffect, useState} from "react";
import Page from "../../../layout/Page";
import {useNavigate, useParams} from "react-router-dom";
import applicationService from "../../../service/ApplicationService";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import PageActions from "../../../components/PageActions";
import {useForm} from "react-hook-form";
import {MessageType} from "../../../util/Enums";
import useMessage from "../../../util/useMessage";
import BackButton from "../../../ui/BackButton";
import SaveButton from "../../../ui/SaveButton";
import Panel from "../../../ui/Panel";
import Input from "../../../ui/Input";

const ApplicationEditPage = ({title}) => {

    const param = useParams();
    const applicationId = param?.id;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [application, setApplication] = useState(null);

    const {handleSubmit, register, formState: {errors}} = useForm();

    useEffect(() => {
        if (applicationId) {
            (async () => {
                setPageLoading(true);
                try {
                    const [_result] = await Promise.all([
                        applicationService.get(applicationId),
                    ]);
                    initPageValues(_result);
                } catch (e) {
                    console.error(e);
                }
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
        }
    }, [applicationId]);

    const initPageValues = (_application) => {
        setApplication(_application);
    }

    const onSubmit = async (data) => {

        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                id: applicationId | null,
                ...data,
            }
            const resultObject = await applicationService.saveOrUpdate(applicationId, obj);
            showMessage(t(applicationId ? 'message.updated' : 'message.created'));

            if (!applicationId) {
                navigate(`/commons/applications/edit/${resultObject._id}`, {replace: true});
            } else {
                initPageValues(resultObject);
            }

        } catch ({code, message}) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    }


    const ActionPanel = () => (
        <PageActions>
            <BackButton loading={loading}/>
            <SaveButton loading={loading}/>
        </PageActions>
    );


    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth={"sm"}>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel/>

                    <Panel title={t('title.application')}>
                        <Input
                            defaultValue={application?.name}
                            label={t('label.name')}
                            error={errors?.name}
                            {...register("name", {
                                required: t('validation.required')
                            })}
                        />
                        <Input
                            defaultValue={application?.code}
                            label={t('label.code')}
                            error={errors?.code}
                            {...register("code", {
                                required: t('validation.required')
                            })}
                        />
                    </Panel>
                    <ActionPanel/>
                </form>

            </Container>
        </Page>
    );

}

export default ApplicationEditPage;
