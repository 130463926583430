import Page from "../layout/Page";
import {useTranslation} from "react-i18next";
import React from "react";
import Grid from "@mui/material/Grid";

const Dashboard = () => {
    const {t} = useTranslation();

    return (
        <Page title={t('menu.dashboard')}>

            <Grid container spacing={2}>
                <Grid item md={4}>

                </Grid>
            </Grid>

        </Page>
    )
}

export default Dashboard;
