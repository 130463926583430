import React from "react";
import InputLabel from "@mui/material/InputLabel";
import {Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';
import MaterialSelect from '@mui/material/Select';

const Select = ({
                    name,
                    label,
                    error,
                    disabled,
                    multiple,
                    defaultValue,
                    rules,
                    control,
                    showErrorMessage = true,
                    ...props
                }) => {

    return (

        <FormControl
            fullWidth
            margin="dense"
            error={error && true}
            disabled={disabled}
            size="small"
            variant="outlined">
            {label &&
                <InputLabel id={`${name}-label`}>
                    {label}
                </InputLabel>
            }
            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={
                    ({field}) =>
                        <MaterialSelect
                            {...field}
                            id={name}
                            displayEmpty
                            label={label}
                            labelId={`${name}-label`}
                            multiple={multiple}>
                            {props.children}
                        </MaterialSelect>
                }/>
            {showErrorMessage && error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    )

}

export default Select;
