import ApplicationInput from "../../ui/ApplicationInput";
import CategoryInput from "../../ui/CategoryInput";
import Input from "../../ui/Input";
import {parseAndFormatDateForInput} from "../../util/DateUtil";
import Panel from "../../ui/Panel";
import React from "react";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";

const CouponEditInputPanel = ({coupon}) => {

    const {t} = useTranslation();
    const {register, control, watch, formState: {errors}} = useFormContext();

    const application = watch('application');

    return (
        <Panel title={t('title.coupon')}>
            <ApplicationInput
                name="application"
                defaultValue={coupon?.application}
                control={control}
                rules={{required: t('validation.required')}}
                error={errors?.application}
            />

            <CategoryInput
                name="category"
                applicationId={application?._id}
                defaultValue={coupon?.category}
                control={control}
                rules={{required: t('validation.required')}}
                error={errors?.category}
            />

            <Input
                defaultValue={coupon?.title}
                label={t('label.title')}
                error={errors?.title}
                {...register("title", {
                    required: t('validation.required')
                })}
            />

            <Input
                type="date"
                defaultValue={coupon?.date ? parseAndFormatDateForInput(coupon.date) : ""}
                label={t('label.date')}
                InputLabelProps={{shrink: true}}
                error={errors?.date}
                {...register("date", {
                    required: t('validation.required')
                })}
            />

        </Panel>
    )

}

export default CouponEditInputPanel;