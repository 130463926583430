import React from "react";
import {Navigate,} from "react-router-dom";
import {useSelector} from "react-redux";


const PrivateRoute = ({children}) => {

    const {isLoggedIn} = useSelector(state => state.auth);

    return isLoggedIn === true ? (
        children
    ) : (
        <Navigate
            replace
            to="/login"
        />
    );
};

export default PrivateRoute;
