import BaseService from "./BaseService";

class ApplicationService extends BaseService {

    constructor() {
        super("/applications");
    }

}

const applicationService = new ApplicationService();

export default applicationService;
