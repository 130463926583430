import React, {useRef, useState} from "react";
import {
    Card,
    CardHeader,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from "@mui/material";
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {makeStyles, withStyles} from '@mui/styles';
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import NativeSelect from '@mui/material/NativeSelect';
import useMessage from "../util/useMessage";
import {MessageType} from "../util/Enums";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#f6f7f9',
        color: '#26415c',
        fontWeight: 'bold'
    }
}))(TableCell);

const SearchInput = withStyles((theme) => ({
    root: {
        width: '100%',
    },
    input: {
        width: '100%',
    }
}))(InputBase);

const SearchSelect = withStyles((theme) => ({
    root: {
        width: '100%',
        border: "none"
    }
}))(NativeSelect);

const useStyles = makeStyles((theme) => ({
    card: {
        height: '100%',
    },
    title: {
        fontSize: '1.2rem'
    },
    root: {
        paddingBottom: '0px',
    },
    contentRoot: {
        paddingTop: '8px',
    }
}));


const Loading = React.memo(({colSpan}) => (
    <TableRow>
        <TableCell colSpan={colSpan + 2} style={{textAlign: 'center'}}>
            <div style={{padding: 12}}>
                <CircularProgress size={24}/>
                <div style={{padding: 10}}>YÜKLENİYOR <br/> LÜTFEN BEKLEYİN</div>
            </div>
        </TableCell>
    </TableRow>
));

const NoResultFound = React.memo(({colSpan, text}) => (
    <TableRow>
        <TableCell colSpan={colSpan + 2} style={{textAlign: 'center'}}>
            <div style={{fontWeight: 'bold', padding: 12}}>{text}</div>
        </TableCell>
    </TableRow>
));

const LabelCell = React.memo(({column, orderBy, handleOrderBy, hasOrderBy}) => {

    return (
        <StyledTableCell
            align={column.numeric ? 'right' : 'left'}
            width={column.width}
            sortDirection={orderBy?.name === column.name ? orderBy?.type : false}>

            {hasOrderBy &&
                <TableSortLabel
                    active={orderBy?.name === column.name}
                    direction={orderBy?.name === column.name ? orderBy?.type : 'asc'}
                    onClick={() => handleOrderBy(column.name)}>
                    {column.label}
                </TableSortLabel>
            }

            {!hasOrderBy && column.label}

        </StyledTableCell>
    );
});

const SearchCell = React.memo(({column, defaultValue = "", placeholder, onSearch}) => {

    const [value, setValue] = useState(defaultValue);
    const process = useRef();

    const handleOnChange = (e) => {
        setValue(e.target.value);
        setSearchData(e.target.value);
    }

    const handleOnSelect = (e) => {
        setValue(e.target.value);
        setSearchData(e.target.value)
    }

    const clear = () => {
        setValue("");
        if (onSearch) {
            setSearchData(null);
        }
    }

    const onKeyPress = (e) => {
        if (e.charCode === 13 && onSearch) {
            setSearchData(value);
        }
    }

    const setSearchData = (val) => {
        if (process.current) {
            clearTimeout(process.current);
        }

        if (val === "null") {
            val = undefined;
        }

        process.current = setTimeout(() => {
            const obj = {};
            let _value = val;
            if (val && column.type) {
                if (column.type === Boolean) {
                    _value = Boolean(Number(val))
                } else if (column.type === Date) {
                    _value = val;
                } else {
                    _value = column.type(val);
                }
            }
            obj[column.searchField || column.name] = _value;
            onSearch(obj);
        }, 250);

    }

    const getInputType = (type) => {
        switch (type) {
            case Number:
                return "number";
            case Date:
                return "date";
            default:
                return "text";
        }
    };

    return (
        <TableCell style={{
            backgroundColor: '#f9f6f3',
            margin: 0,
            padding: '0 24px 0 16px'
        }}>
            {column?.searchType === 'select' ?
                <SearchSelect
                    disableUnderline
                    value={value}
                    onChange={handleOnSelect}>
                    <option value="">Tümü</option>
                    {column.selectOptions}
                </SearchSelect>
                :
                <SearchInput
                    value={value}
                    onChange={handleOnChange}
                    onKeyPress={onKeyPress}
                    autoComplete="off"
                    placeholder={`${placeholder}...`}
                    type={getInputType(column.type)}
                    startAdornment={
                        <SearchIcon fontSize="small" style={{marginRight: 5}}/>
                    }
                    endAdornment={
                        value.length === 0 ? undefined :
                            <HighlightOffIcon
                                fontSize="small"
                                style={{cursor: "pointer"}}
                                onClick={clear}
                            />
                    }
                />
            }
        </TableCell>
    );
});


const DefaultContainerComponent = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Card style={props.style} className={classes.card}>
            <CardHeader
                title={props.title}
                action={props.action}
                classes={{title: classes.title, root: classes.root}}
                subheader={props.subtitle}
                {...(props.selected ? {subheader: t('title.selected', {value: props.selected?.length || 0})} : {})}
            />
            {props.children}
        </Card>

    )
}

const DataTable = React.memo((
        {
            title,
            subtitle,
            data,
            loading,
            columns,
            count,
            page,
            setPage,
            orderBy,
            setOrderBy,
            max,
            setMax,
            searchData,
            setSearchData,
            onEdit,
            onDelete,
            ManageComponent,
            action,
            selected,
            setSelected,
            setLoading,
            selectAll,
            cardStyle,
            service,
            search,
            ContainerComponent = DefaultContainerComponent
        }
    ) => {

        const {t} = useTranslation();

        const isSearchable = columns.some(item => item.searchable);
        const showMessage = useMessage();

        const resolve = (item, column, rowIndex, colIndex) => {
            if (column.execute) {
                if (column.passItem) {
                    return column.execute(item, rowIndex, colIndex);
                } else {
                    return column.execute(item[column.name], rowIndex, colIndex);
                }
            }
            return item[column.name];
        }

        const handleOrderBy = (name) => {
            setOrderBy({
                name: name,
                type: orderBy?.name === name && orderBy?.type === 'asc' ? 'desc' : 'asc'
            });
        }

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setMax(parseInt(event.target.value, 10));
            setPage(0);
        };

        const isSelected = (item) => {
            return selected?.some(some => some._id === item._id);
        }

        const onSelect = (item, e) => {
            if (e.target.checked) {
                setSelected && setSelected([...selected, item]);
            } else {
                const items = selected?.filter(i => i._id !== item._id);
                setSelected && setSelected(items);
            }
        }

        // silinmis kaydi geri al.
        const onRollback = async (item, index) => {
            try {
                setLoading(true);
                await service.rollback(item?._id);
                await search();
                showMessage(t('message.updated'));
            } catch ({code, message}) {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
            } finally {
                setLoading(false);
            }
        }

        const EmptyHeadCell = () => (
            <TableCell style={{
                backgroundColor: '#f9f6f3',
                margin: 0,
            }}/>
        );


        return (
            <ContainerComponent
                style={cardStyle}
                title={title}
                action={action}
                subheader={subtitle}
                selected={selected}>
                <TableContainer>
                    <Table size="small" className="data-table">
                        <TableHead>
                            {isSearchable &&
                                <TableRow>
                                    {setSelected &&
                                        <EmptyHeadCell/>
                                    }
                                    {columns.map((column, index) => (
                                        column.searchable ?
                                            <SearchCell
                                                key={index}
                                                placeholder={t('label.search')}
                                                column={column}
                                                defaultValue={column.defaultValue !== undefined ? column.defaultValue : ""}
                                                onSearch={(data) => {
                                                    setSearchData({
                                                        ...searchData,
                                                        ...data
                                                    })
                                                }}
                                            />
                                            : <EmptyHeadCell key={index}/>
                                    ))}

                                    {onEdit && <EmptyHeadCell/>}

                                    {onDelete && <EmptyHeadCell/>}

                                    {ManageComponent && <EmptyHeadCell/>}
                                </TableRow>
                            }

                            <TableRow>
                                {setSelected &&
                                    <StyledTableCell padding="checkbox" width={10} style={{textAlign: 'center'}}>
                                        {selectAll && <Checkbox style={{padding: 0}}/>}
                                    </StyledTableCell>
                                }
                                {columns.map((column, index) => (
                                    <LabelCell
                                        key={index}
                                        column={column}
                                        orderBy={orderBy}
                                        hasOrderBy={setOrderBy}
                                        handleOrderBy={handleOrderBy}
                                    />
                                ))}
                                {onEdit && <StyledTableCell width={50}/>}
                                {onDelete && <StyledTableCell width={50}/>}
                                {ManageComponent && <StyledTableCell width={50}/>}
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {loading && <Loading colSpan={columns.length}/>}

                            {data?.map((item, rowIndex) => (
                                <TableRow hover key={item._id || rowIndex} selected={isSelected(item)}>

                                    {setSelected &&
                                        <TableCell padding="checkbox" style={{textAlign: 'center'}}>
                                            <Checkbox
                                                style={{padding: 0}}
                                                defaultChecked={isSelected(item)}
                                                onChange={(e) => {
                                                    onSelect(item, e);
                                                }}
                                            />
                                        </TableCell>
                                    }

                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            {resolve(item, column, rowIndex, colIndex)}
                                        </TableCell>
                                    ))}

                                    {onEdit &&
                                        <TableCell style={{padding: 0, textAlign: "center"}}>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                style={{padding: 0}}
                                                onClick={() => {
                                                    onEdit(item, rowIndex)
                                                }}>
                                                {t('button.edit')}
                                            </Button>
                                        </TableCell>
                                    }

                                    {onDelete &&
                                        <TableCell style={{padding: 0, textAlign: "center"}}>
                                            {!item?.isDeleted &&
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    style={{padding: 0}}
                                                    onClick={() => {
                                                        onDelete(item, rowIndex)
                                                    }}>
                                                    {t('button.delete')}
                                                </Button>
                                            }

                                            {item.isDeleted && service &&
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    style={{padding: 0}}
                                                    onClick={async () => {
                                                        await onRollback(item, rowIndex)
                                                    }}>
                                                    {t('button.rollback')}
                                                </Button>
                                            }
                                        </TableCell>
                                    }

                                    {ManageComponent &&
                                        <TableCell style={{padding: 0, textAlign: "center"}}>
                                            <ManageComponent item={item}/>
                                        </TableCell>
                                    }
                                </TableRow>
                            ))}

                            {(!loading && count === 0) &&
                                <NoResultFound
                                    colSpan={columns.length}
                                    text={t('message.noResultFound')}
                                />
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {setPage &&
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={max}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }

                {!setPage &&
                    <div style={{height: 10}}/>
                }
            </ContainerComponent>
        );
    }
);

export
{
    DataTable,
    StyledTableCell,
    DefaultContainerComponent
}
