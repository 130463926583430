import BaseService from "./BaseService";

class SubscriptionService extends BaseService {

    constructor() {
        super("/subscriptions");
    }

}

const subscriptionService = new SubscriptionService();

export default subscriptionService;
