import BaseService from "./BaseService";

class LeagueService extends BaseService {

    constructor() {
        super("/leagues");
    }

}

const leagueService = new LeagueService();

export default leagueService;
