import React, {useEffect, useState} from "react";
import Page from "../../../layout/Page";
import {useNavigate, useParams} from "react-router-dom";
import categoryService from "../../../service/CategoryService";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import PageActions from "../../../components/PageActions";
import {useForm} from "react-hook-form";
import {MessageType} from "../../../util/Enums";
import useMessage from "../../../util/useMessage";
import BackButton from "../../../ui/BackButton";
import SaveButton from "../../../ui/SaveButton";
import Panel from "../../../ui/Panel";
import Input from "../../../ui/Input";
import ApplicationInput from "../../../ui/ApplicationInput";
import SubscriptionInput from "../../../ui/SubscriptionInput";
import ImagePickerButton from "../../../ui/ImagePickerButton";

const CategoryEditPage = ({title}) => {

    const param = useParams();
    const categoryId = param?.id;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [category, setCategory] = useState(null);
    const [image, setImage] = useState(null);

    const {handleSubmit, control, watch, setValue, register, formState: {errors}} = useForm();

    const application = watch('application');

    useEffect(() => {
        if (categoryId) {
            (async () => {
                setPageLoading(true);
                try {
                    const [_result] = await Promise.all([
                        categoryService.get(categoryId),
                    ]);
                    initPageValues(_result);
                } catch (e) {
                    console.error(e);
                }
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
        }
    }, [categoryId]);

    const initPageValues = (_category) => {
        setCategory(_category);
        setImage(_category?.image);
        setValue("application", _category.application);
    }

    const onSubmit = async (data) => {

        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                id: categoryId | null,
                ...data,
                image
            }
            const resultObject = await categoryService.saveOrUpdate(categoryId, obj);
            showMessage(t(categoryId ? 'message.updated' : 'message.created'));

            if (!categoryId) {
                navigate(`/commons/categories/edit/${resultObject._id}`, {replace: true});
            } else {
                initPageValues(resultObject);
            }

        } catch ({code, message}) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    }


    const ActionPanel = () => (
        <PageActions>
            <BackButton loading={loading}/>
            <SaveButton loading={loading}/>
        </PageActions>
    );


    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth={"sm"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel/>
                    <Panel title={t('title.category')}>
                        <ApplicationInput
                            name="application"
                            defaultValue={category?.application}
                            control={control}
                            rules={{required: t('validation.required')}}
                            error={errors?.application}
                        />
                        <Input
                            defaultValue={category?.name}
                            label={t('label.name')}
                            error={errors?.name}
                            {...register("name", {
                                required: t('validation.required')
                            })}
                        />
                        <SubscriptionInput
                            multiple
                            name="subscriptions"
                            applicationId={application?._id}
                            defaultValue={category?.subscriptions}
                            control={control}
                            error={errors?.subscriptions}
                        />
                        <Input
                            multiline
                            maxRows={4}
                            defaultValue={category?.announcement}
                            label={t('label.announcement')}
                            error={errors?.announcement}
                            {...register("announcement")}
                        />
                        <ImagePickerButton {...{image, setImage}} />
                    </Panel>
                    <ActionPanel/>
                </form>
            </Container>
        </Page>
    );

}

export default CategoryEditPage;
