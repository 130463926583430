import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import {useTranslation} from "react-i18next";

const Loading = () => {
    const {t} = useTranslation();
    return (
        <div style={{textAlign: 'center'}}>
            <CircularProgress/>
            <div style={{padding: 8, fontSize: 13, fontWeight: 'bold'}}>
                {t('message.loading')}<br/>
                {t('message.pleaseWait')}
            </div>
        </div>
    )
};

export default Loading;
