import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Button from "@mui/material/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const BackButton = ({loading}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    return (
        <Button
            variant="contained"
            disabled={loading}
            startIcon={loading ? null : <KeyboardBackspaceIcon/>}
            onClick={() => {
                navigate(-1);
            }}>
            {t('button.back')}
        </Button>
    )

}

export default BackButton;
