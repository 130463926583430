import React, {useEffect, useRef, useState} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import leagueService from "../service/LeagueService";
import {useTranslation} from "react-i18next";
import {Controller} from "react-hook-form";
import RenderTags from "./RenderTags";


const LeagueInput = ({
                         name,
                         label,
                         defaultValue,
                         rules,
                         error,
                         multiple = false,
                         disabled,
                         control,
                         limitTags = 2,
                         placeholder,
                         showErrorMessage
                     }) => {

    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const {t} = useTranslation();

    const searchTimeout = useRef(null);

    useEffect(() => {
        let active = true;
        setLoading(true);
        if (!inputValue) {
            setOptions([]);
            setLoading(false);
        } else {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }
            searchTimeout.current = setTimeout(async () => {
                try {
                    setLoading(true);
                    const {data} = await leagueService.search({
                        start: 0,
                        data: {name: inputValue},
                        max: 50
                    });
                    const result = data.map(item => ({...item}));
                    if (active) {
                        setOptions(result || []);
                        setLoading(false);
                    }
                } catch (e) {

                }
            }, 500);
        }
        return () => {
            active = false;
        };
    }, [inputValue]);

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };


    const filterOptions = (options, params) => {
        const filtered = [...options];

        if (!loading && filtered.length === 0 && params.inputValue !== '') {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    }


    return (
        <Controller
            render={({field}) => (
                <Autocomplete
                    {...field}
                    freeSolo
                    multiple={multiple}
                    getOptionLabel={(league) => league.name || ""}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    options={options}
                    filterOptions={filterOptions}
                    loading={loading}
                    limitTags={limitTags}
                    disabled={disabled}
                    renderTags={RenderTags}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={placeholder ? undefined : (label || (multiple ? t('label.categories') : t('label.league')))}
                            error={error && true}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            placeholder={placeholder}
                            autoComplete="off"
                            helperText={showErrorMessage ? error?.message : undefined}
                            onChange={handleChange}
                        />
                    )}
                    onChange={async (_, data) => {
                        field.onChange(data);
                    }}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : (multiple ? [] : "")}
            rules={rules}
        />
    );
};

export default LeagueInput;
